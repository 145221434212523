/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const deploy = {
  "aws_cognito_identity_pool_id": "ap-northeast-2:5fe25cc7-f8e4-4448-b2af-fb44ee3b1640",
  "aws_user_pools_id": "ap-northeast-2_xpaDNvqyH",
  "aws_user_pools_web_client_id": "faj3ql79pg67br56r2ha4143e",
  "domain": "lifemanagerapp5939252a-5939252a-devl.auth.ap-northeast-2.amazoncognito.com",
  "scope": [
    "phone",
    "email",
    "openid",
    "profile",
    "aws.cognito.signin.user.admin"
  ],
  "redirectSignIn": "https://lifemanager.pkiop.me/",
  "redirectSignOut": "https://lifemanager.pkiop.me/",
  // "redirectSignIn": "http://localhost:3000/",
  // "redirectSignOut": "http://localhost:3000/"
  "aws_appsync_graphqlEndpoint": "https://7yjlfh6x7ncv5i5k5emx3ugaxm.appsync-api.ap-northeast-2.amazonaws.com/graphql",
}

const devEnv = {
  "aws_cognito_identity_pool_id": "ap-northeast-2:fdb3340e-9248-4855-a2a6-3e6a5e522a98",
  "aws_user_pools_id": "ap-northeast-2_SPWWXnF0X",
  "aws_user_pools_web_client_id": "4f8m3nk6sqjk5sfv1i91cf5t3g",
  "domain": "lifemanagerdev.auth.ap-northeast-2.amazoncognito.com",
  "scope": [
    "phone",
    "email",
    "openid",
    "profile",
    "aws.cognito.signin.user.admin"
  ],
  "redirectSignIn": "http://localhost:3000/",
  "redirectSignOut": "http://localhost:3000/",
  "aws_appsync_graphqlEndpoint": "https://3mt7wi734zbsrp5bvajtyu3e3e.appsync-api.ap-northeast-2.amazonaws.com/graphql"
}

const isDev = false;
const env = isDev ? devEnv : deploy;

const awsmobile = {
  "aws_project_region": "ap-northeast-2",
  "aws_cognito_region": "ap-northeast-2",
  "aws_cognito_identity_pool_id": env["aws_cognito_identity_pool_id"],
  "aws_user_pools_id": env["aws_user_pools_id"] ,
  "aws_user_pools_web_client_id": env["aws_user_pools_web_client_id"],
  "oauth": {
    "domain": env["domain"],
    "scope": env["scope"],       
    "redirectSignIn": env["redirectSignIn"],
    "redirectSignOut": env["redirectSignOut"],
    "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_appsync_graphqlEndpoint": env["aws_appsync_graphqlEndpoint"],
  "aws_appsync_region": "ap-northeast-2",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};

console.log("awsmobile : ", awsmobile);


export default awsmobile;
